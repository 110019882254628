@use '~@angular/material' as mat;
$black: #000;
$white: #fff;
$yellow: #FFD32D;
$grey-text: #999;
$orange-text: orangered;
$orange-bg: orangered;
$green-bg: #28706C;
$pink-bg: #F7B4A6;
$gray: gray;
$light-grey: #f0f0f0;
$light-black: #333;
$light-background: #c6c6c6;
$dark-background: #777a79;
$light-grey-bg: #f9f9f9;
$theme-background: #e5e5e5;
$theme-primary: #ff1872;
$theme-secondary: #18ffa5;
$theme-bgc: #f8fffe;
$theme-bgc-primary: #ffedf4;
$theme-bgc-secondary: #cef9e8;
$theme-bgc-add-btn: #a3ffdb;
$theme-bgc-ternary: #faf0bf;
$theme-bgc-quaternary: #dbdff2;
$theme-border: #ececec;
$btn-bgc-primary: #ff74aa;
$theme-reject: #eb5757;
$theme-violet:#DBDFF2;

@import "~@angular/material/theming";

@include mat.core();

$istharaErp-primary: mat.define-palette(mat.$pink-palette);
$istharaErp-accent: mat.define-palette(mat.$light-green-palette, A700);

$istharaErp-warn: mat.define-palette(mat.$red-palette);
$istharaErp-primary-color: mat.get-color-from-palette($istharaErp-primary);
$istharaErp-theme: mat.define-light-theme(
  (
    color: (
      primary: $istharaErp-primary,
      accent: $istharaErp-accent,
      warn: $istharaErp-warn,
    ),
  )
);
$erp-checkbox-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$grey-palette, 900),
      accent: mat.define-palette(mat.$light-green-palette, A700),
      warn: mat.define-palette(mat.$indigo-palette),
    ),
  )
);

@include mat.all-component-themes($istharaErp-theme);

.dark-theme {
  // This mixin only generates the color styles now.
  @include mat.all-component-colors($erp-checkbox-theme);
}

// mixins
@mixin text-size($size, $font, $color, $style, $weight) {
  font-family: $font;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin primary-btn($bg, $font-color, $font-size) {
  color: $font-color;
  background: $bg;
  font-size: $font-size;
  font-family: nunitoReg;
  border: 1px solid $font-color;
  border-radius: 8px;
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

@mixin h-text($fontFamily, $size, $weight, $linehignt, $style, $color) {
  font-family: $fontFamily;
  font-size: $size;
  font-weight: $weight;
  line-height: $linehignt;
  font-style: $style;
  color: $color;
  // margin-top: 10px;
}

// $primary: purple;
// $link-hover-color: $theme-primary;
// $danger: red;
// $theme-colors: (
//   "primary": $theme-primary,
// ) !default;

// @import "bootstrap";
.mat-remove-underline {
  .mat-form-field-underline{
    display: none;
  }
}