@import "./theme.scss";

@font-face {
  font-family: nunitoReg;
  src: url("./../../assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: nunitoBold;
  src: local("Nunito-Bold"),
    url("./../../assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
}

/* width */
::-webkit-scrollbar {
  width: 0.1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey-text;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $white;
}

::-webkit-scrollbar {
  display: none;
}

.custom-slider {
  .ngx-slider {
    .ngx-slider-pointer {
      background-color: deeppink !important;
    }

    .ngx-slider-pointer:after {
      background-color: white !important;
    }

    .ngx-slider-selection {
      background-color: deeppink !important;
    }
  }
}

button:disabled {
  background-color: $grey-text !important;
  border-color: $grey-text !important;
  color: $white !important;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.no-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-dialog-container {
    padding: 0;
  }
}

.custom-notification-bottom-sheet {
  padding: 0px !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  max-height: 100vh !important;
}

@media screen and (max-width: 475px) {
  .ng-otp-input-wrapper {
    display: flex;
    justify-content: space-around;

    input {
      width: 35px !important;
      height: 35px !important;
    }
  }
}

.mat-radio-button {
  .mat-radio-label-content {
    white-space: break-spaces !important;
  }
}
